import {animateScroll} from '../../vendor/animate-scroll';

const $whiteWrap = $('.white-wrap--js');
const $whiteWrapSuccess = $('.whie-wrap__hide-success--js');
const $result = $('#result');

/**
 * Validate phone field
 */
function checkPhone() {
	const btn = $(this).parents('.form').find('.btn-submit--js');

	if ($(this).val().length > 1 && !$(this).val().includes('_')) {
		btn.prop('disabled', false);
	} else {
		btn.prop('disabled', true);
	}
}

$('.phone-mask--js').on('click input blur focus change keyup keydown', checkPhone);

/**
 * Callback Form
 */
$('#callbackForm').simpleSendForm({
	autoClear: false,
	successInPopup: false,
	successMarkup: `		
	  <div class="form__success-mw360">
		  <h4 class="form__success-title">Спасибо!</h4>
		  <p class="form__success-text">До того как мы перезвоним, посмотрите больше информации о нашей компании на основном сайте</p>
		  <a class="btn btn-outline-primary btn-round btn-s54" href="https://zalog-sro.ru" target="_blank" onclick="ym(69668710,'reachGoal','main_website')">Перейти на основной сайт</a>
	  </div>
	`,
}, () => {
	ym(69668710, 'reachGoal', 'call_back_sent');
	// console.log(' Обратный звонок отправлен ');
});

/**
 * Sro Form
 */
$('#sroForm').simpleSendForm({
	autoClear: false,
	successInPopup: false,
	successMarkup: `
	  <div class="form__success-mw360">
		  <h4 class="form__success-title">Спасибо!</h4>
		  <p class="form__success-text">До того как мы перезвоним, посмотрите больше информации о нашей компании на основном сайте</p>
		  <a class="btn btn-outline-primary btn-round btn-s54" href="https://zalog-sro.ru" target="_blank" onclick="ym(69668710,'reachGoal','main_website')">Перейти на основной сайт</a>
	  </div>
	`,
}, () => {
	ym(69668710, 'reachGoal', 'sro_sent');
	// console.log(' SRO форма отправлена ');
});

/**
 * Edit phone Form
 */
const quizPhone = document.getElementById('quizPhone');
const editPhoneInput = document.getElementById('editPhoneInput');
const editPhoneForm = document.getElementById('editPhoneForm');
const editPhoneFormBtn = editPhoneForm.querySelector('.btn-submit--js');
const badPhone = editPhoneForm.querySelector('#badPhone');

$('#editPhoneForm').simpleSendForm({
	autoClear: true,
	successMarkup: `
		<h4 class="form__success-title mb-0">Ваш телефон успешно изменен!</h4>
	`,
});

quizPhone.addEventListener('keyup', () => {
	editPhoneInput.value = quizPhone.value;
	badPhone.value = quizPhone.value;

	if (editPhoneInput.value === quizPhone.value) {
		editPhoneFormBtn.disabled = true;
	}
});

editPhoneInput.addEventListener('keyup', () => {
	editPhoneFormBtn.disabled = editPhoneInput.value === quizPhone.value;
});

/**
 * Quiz Form
 */
export let sendForm = $('#quizForm')
	.simpleSendForm({
		successTitle: '',
		successText: '',
		// errorMessPlace: '.quiz__get-phone-field--js',
		debug: false,
	}, () => {
		$whiteWrap.removeClass('white-wrap--inactive')
			.parent()
			.removeClass('is-inactive').addClass('mt-30');
		$whiteWrapSuccess.hide();
		$('body').removeClass('is-getphone-active result-active');
		animateScroll($result, 500);

		// Цель Яндекс метрики - переход к результату
		ym(69668710, 'reachGoal', 'result');
		// console.log(' Основная форма отправлена ');
	});
