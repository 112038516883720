import {animationPercent} from '../../spinner-block/spinner-block';
import {animateScroll} from '../../../vendor/animate-scroll';

export default class Quiz {
	constructor(questions, results, elements) {
		// Массив с вопросами
		this.questions = questions;

		// Массив с возможными результатами
		this.results = results;

		// Количество набранных очков
		this.score = 0;

		// Номер результата из массива
		this.result = 0;

		// Номер текущего вопроса
		this.current = 0;

		// Элементы - объект с данными
		this.elements = elements;
	}

	change() {
		if (this.current < this.questions.length) {
			this.showBlock();
			this.progress();

			const mainBlock = document.getElementById('mainBlock');
			// Плавно скроллим до верхнего блока контейнера
			animateScroll($(mainBlock), 700);
		} else {
			this.hideBlock();
			this.progress(true);
		}

		const prevBtn = document.querySelector('.btn-prev--js');
		if (this.current >= 1) {
			prevBtn.style.display = 'inline';
			prevBtn.parentNode.nextElementSibling.classList.remove('col-12');
			prevBtn.parentNode.nextElementSibling.classList.add('col-6');
		} else {
			prevBtn.style.display = 'none';
			prevBtn.parentNode.nextElementSibling.classList.add('col-12');
			prevBtn.parentNode.nextElementSibling.classList.remove('col-6');
		}
	}

	// Переход к следующему вопросу
	next(event) {
		this.current++;

		if (this.current >= this.questions.length) {
			this.end();
		}

		this.change(event);
		const nextBtn = this.elements.nextBtn;

		nextBtn.disabled = false;
		setTimeout(() => {
			nextBtn.disabled = true;
		}, 100);
	}

	// Переход к предыдущему вопросу
	prev() {
		this.current--;
		this.change();
	}

	// Если вопросы кончились, этот метод проверит, какой результат получил пользователь
	end() {
		const getPhone = document.querySelector('.quiz__get-phone--js');
		const body = document.querySelector('body');
		const btns = document.querySelector('.quiz__btns--js');
		// Показываем блок захвата телефона
		getPhone.style.display = 'block';
		body.classList.add('is-getphone-active');
		// Цель Яндекс Метрики
		console.log(' Перешел к блоку захвата телефона ');
		ym(69668710, 'reachGoal', 'contacts');
		// Анимируем проценты
		animationPercent(0, 100, this.elements.percentCounter, () => {
			// Показываем блок результата, но пока скрытым
			this.elements.whiteWrap.parentNode.classList.remove('d-none');
			this.elements.whiteWrap.classList.add('white-wrap--inactive');
			body.classList.add('result-active');
		});
		// Скрываем кнопки навигации
		btns.style.display = 'none';
	}

	hideBlock() {
		// Скрыть все блоки вопросов
		for (let i = 0; i < this.elements.questBlock.length; i++) {
			this.elements.questBlock[i].classList.remove('is-active');

			setTimeout(() => {
				this.elements.questBlock[i].classList.remove('is-fadein');
			}, 50);
		}
	}

	get currentQuestion() {
		return document.getElementById(`quizQuestBlock-${this.current + 1}`);
	}

	showBlock() {
		this.hideBlock();

		// Если не последний, то активируем следущий
		if (this.current <= this.questions.length) {
			this.currentQuestion.classList.add('is-active');

			setTimeout(() => {
				this.currentQuestion.classList.add('is-fadein');
			}, 100);

			// Цели яндекс меткрики
			switch (this.currentQuestion.id) {
				case 'quizQuestBlock-2':
					// console.log(' Переход ко 2-му вопросу ');
					ym(69668710, 'reachGoal', '2_question');
					break;

				case 'quizQuestBlock-3':
					// console.log(' Переход ко 3-му вопросу ');
					ym(69668710, 'reachGoal', '3_question');
					break;

				case 'quizQuestBlock-4':
					// console.log(' Переход ко 4-му вопросу ');
					ym(69668710, 'reachGoal', '4_question');
					break;

				case 'quizQuestBlock-5':
					// console.log(' Переход ко 5-му вопросу ');
					ym(69668710, 'reachGoal', '5_question');
					break;

				case 'quizQuestBlock-6':
					// console.log(' Переход ко 6-му вопросу ');
					ym(69668710, 'reachGoal', '6_question');
					break;

				default:
					console.log('Больше нет вопросов');
			}
		} else {
			this.currentQuestion.classList.remove('is-active');
		}
	}

	progress(allActive = false) {
		this.elements.progress.innerHTML = '';

		const text = (step, num) => `<span>${num} из ${this.questions.length}</span>`;

		for (let i = 1; i <= this.questions.length; i++) {
			let step = document.createElement('div');
			step.classList.add('progress__step');

			if (i === this.current + 1) {
				step.classList.add('is-active');
				step.innerHTML = text(step, i);
			}

			if (allActive) {
				step.classList.add('is-active');

				if (i === this.questions.length) {
					step.innerHTML = text(step, i);
				}
			}

			this.elements.progress.appendChild(step);
		}
	}
}
