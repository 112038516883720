import 'magnific-popup';

jQuery(document).ready(($) => {
	/**
	 * Type image
	 */
	$('.mfp-image--js').magnificPopup({
		type: 'image',
		mainClass: 'mfp-scale',
		removalDelay: 160,
	});

	/**
	 * Type inline
	 */
	$('.mfp-window--js').magnificPopup({
		type: 'inline',
		fixedContentPos: true,
		fixedBgPos: true,
		overflowY: 'auto',
		closeBtnInside: true,
		preloader: false,
		midClick: true,
		mainClass: 'mfp-scale',
		removalDelay: 160,
	});

	/**
	 * Type iframe (video)
	 */
	$('.mfp-video--js').magnificPopup({
		type: 'iframe',
		mainClass: 'mfp-scale',
		removalDelay: 160,
		preloader: true,
		fixedContentPos: false,
	});

	/**
	 * Type inline - focus on filed #name
	 */
	$('.mfp-form--js').magnificPopup({
		type: 'inline',
		fixedContentPos: true,
		fixedBgPos: true,
		overflowY: 'auto',
		closeBtnInside: true,
		preloader: false,
		midClick: true,
		mainClass: 'mfp-top-up',
		removalDelay: 160,
		focus: '#name, #sroPhone, #cbPhone',
		callbacks: {
			beforeClose() {
				const content = $(this)[0].container;
				$(content).find('.form__hide-success').removeClass('is-inactive is-hidden');
				$(content).find('.form__sys-message').hide();
				// console.log($(content).find('.form__hide-success'));
			},
			// close() {
			//
			// },
		},
	});

	/**
	 * Type ajax
	 */
	$('.mfp-ajax--js').magnificPopup({
		type: 'ajax',
		fixedContentPos: true,
		fixedBgPos: true,
		overflowY: 'auto',
		closeBtnInside: true,
		preloader: true,
		tLoading: '<img src="/templates/template_zm_it/images/preload.gif" />',
		midClick: true,
		mainClass: 'mfp-scale',
		removalDelay: 160,
	});
}); // end ready
