jQuery(document).ready(() => {
	$('.phone-mask--js').inputmask('+7 (999) 999-99-99', {
		// placeholder: '_ (___) ___-__-__',
		showMaskOnHover: false,
		greedy: false,
		digits: 9,
		// clearIncomplete: true,
		// onKeyDown(event) {
		// },
		// onKeyValidation(key, result) {
		// 	if (result.pos < 17) {
		// 		console.log(result.pos, key);
		// 	}
		// },
		// onincomplete() {
		// 	console.log(' InCompleate ');
		// },
		//
		// oncomplete() {
		// 	console.log(' Compleate ');
		// },
	});
});
