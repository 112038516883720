import {animateScroll} from '../../vendor/animate-scroll';
import enquire from 'enquire.js';

let btn = $('.btn-flare--js');
let main = $('#mainBlock');

btn.click((e) => {
	e.preventDefault();
	animateScroll(main, 300);
});

jQuery(document).ready(($) => {
	const $headerTop = $('.header__top');
	let lastScrollTop = 0;

	function isStatic() {
		$headerTop.removeClass('is-fixed');
		setTimeout(() => {
			$headerTop.removeClass('is-animate');
		}, 500);
	}

	function scrollFn() {
		let st = $(this).scrollTop();
		let mainBlock = document.getElementById('mainBlock').getBoundingClientRect().y;

		if (st > lastScrollTop || mainBlock < 1) {
			isStatic();
		} else if ($(window).scrollTop() >= 210) {
			$headerTop.addClass('is-fixed');
			setTimeout(() => {
				$($headerTop)
					.addClass('is-animate');
			}, 500);
		} else {
			isStatic();
		}
		lastScrollTop = st;
	}

	enquire.register('screen and (max-width: 992px)', {
		match() {
			setTimeout(() => {
				$(window).on('scroll', scrollFn);
			}, 100);
		},
		unmatch() {
			$(window).off('scroll', scrollFn);
		},
	});
});

